import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { mediaQueries } from "../config/mediaQueries"

const themeGreen = "#00baba"
// const themeGreenLight = "#bbeded"

const Container = styled.div`
  background-color: white;
`

const MenuItem = styled.li`
  padding: 0;
  margin: 0;
`

const Menus = styled.ul`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
  ${MenuItem} > a {
    display: inline-block;
    font-size: 1rem;
    font-weight: 500;
    padding: 1.2rem 2rem;
    color: ${themeGreen};
    text-decoration: none;
  }
  ${MenuItem} > a:hover {
    color: #545652;
  }
`

const Nav = styled.nav`
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  border-bottom: 1px solid ${themeGreen};
  ${mediaQueries("sm")`
    width: 550px;
  `}
`

const SiteHeader = () => {
  return (
    <Container>
      <Nav>
        <Menus>
          <MenuItem>
            <Link to="/">Home</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/blog/">Blog</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/works/">Works</Link>
          </MenuItem>
        </Menus>
      </Nav>
    </Container>
  )
}

export { SiteHeader }
