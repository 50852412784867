// breakpoints in pixel
export const breakpoints = {
  sm: 575,
  md: 768,
  lg: 991,
  xl: 1199,
}

// https://www.daggala.com/reusable-media-queries-with-styled-components/
// export const mediaQueries_em = key => {
//   return style => `@media (min-width: ${breakpoints[key]}em) { ${style} }`
// }

export const mediaQueries = key => {
  return style => `@media (min-width: ${breakpoints[key]}px) { ${style} }`
}
