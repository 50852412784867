import React from "react"
import styled from "styled-components"

const Footer = styled.footer`
  background-color: white;
  border-top: 1px solid var(--green-text-color);
  padding: 1rem 0;
  color: #859eae;
  > p {
    text-align: center;
  }
`

const SiteFooter = () => {
  return (
    <Footer>
      <p>© 2022, Bill X.</p>
    </Footer>
  )
}

export { SiteFooter }
