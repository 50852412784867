import { Link } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { SiteHeader } from "../components/SiteHeader"
import { SiteFooter } from "../components/SiteFooter"

const RootContainer = styled.div`
  height: 100%;
`

const Container = styled.div`
  padding: 4rem;
`

export default function WorksPage() {
  return (
    <RootContainer className="bg-content">
      <Helmet>
        <title>xzd.co :: works</title>
      </Helmet>
      <SiteHeader />
      <Container className="text-center">
        <div>Works Page is on building</div>
        <p>
          <Link to="/blog">View blog</Link>
        </p>
      </Container>
      <SiteFooter />
    </RootContainer>
  )
}
